































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import SessionControls from "./details/SessionControls.vue";
import TextAnalysisDetails from "@/components/sessions/details/TextAnalysisDetails.vue";
import AudioAnalysisDetails from "@/components/sessions/details/AudioAnalysisDetails.vue";
import SessionScoresCard from "@/components/sessions/cards/SessionScoresCard.vue";

@Component({
  components: {
    AudioAnalysisDetails,
    TextAnalysisDetails,
    SessionControls,
    SessionScoresCard,
  },
})
export default class SessionDetails extends Vue {
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/has") has!: boolean;
  @Getter("currentSession/loading") loading!: boolean;
  @Getter("currentSession/length") sessionLength!: number;

  @Getter("profile/showTutorial") showTutorial!: boolean;

  @Getter("sessions/gettingSingleSession") singleLoading!: boolean;
  @Getter("sessions/retrievedSessionId") retrievedSessionId!: number;

  @Action("audio/setTotalTime") setTotalTime!: Function;
  @Prop({ default: () => false }) enableFeedback!: boolean;

  get isLoading() {
    return (
      this.loading ||
      (this.singleLoading && this.retrievedSessionId === this.id)
    );
  }

  @Watch("sessionLength", { immediate: true })
  onLengthUpdate() {
    if (this.sessionLength !== 0) this.setTotalTime(this.sessionLength);
  }
}
