import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"elevation":"0","height":"100%"}},[_c(VCardTitle,[_c('div',{staticClass:"headline font-weight-bold text-truncate flex-1"},[_c('span',[_vm._v(_vm._s(_vm.headline))])]),_c('div',{staticClass:"mx-2"}),_c('HowToPopup',{attrs:{"id":5}}),_c('div',{staticClass:"mx-2"}),_c(VTooltip,{attrs:{"top":"","nudge-bottom":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"dark":"","size":"30","color":"primary"}},on),[_vm._v(" mdi-information ")])]}}])},[_c('div',[_c('div',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(_vm._s(_vm.ititle))]),_c('div',[_vm._v(_vm._s(_vm.itext))])])])],1),_c(VCardText,{staticClass:"flex-1 d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"padding":"40px 60px","flex":"1"}},[_c('div',{staticClass:"text-h2 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.wordsPerMinute)+" ")]),_c('div',{staticClass:"font-weight-bold accentText--text text-h6"},[_c('span',[_vm._v(_vm._s(_vm.$t("cards.wpm")))])])]),_c(VDivider,{staticClass:"my-4 darkgray",staticStyle:{"width":"50%"}}),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t("sessionsPage.sessionsListSection.speechSpeedCard.wpmNotice"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }